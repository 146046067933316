<template>
  <div id="category">
    <section class="section is-main">
      <div class="container is-main">
        <h1 class="title is-3 has-text-centered">{{ category.title }}</h1>
        <div class="columns is-multiline is-centered">
          <div class="column is-3 product-card-column" v-for="product of visibleProducts" :key="product.sku">
            <product-tile :product="product" />
          </div>
          <div class="column is-9"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProductTile from '../components/blocks/ProductTile.vue'

export default {
  name: 'Category',
  components: {
    ProductTile
  },
  computed: {
    ...mapState({
      products: state => state.inventory.products,
      categories: state => state.inventory.categories
    }),
    category () {
      return this.categories[this.$route.params.uri]
    },
    visibleProducts () {
      return this.category.visibleProducts.map(x => this.products[x])
    }
  },
  updated () {
    document.title = this.category.title + ' | Lunna Teknik'
  },
  created () {
    document.title = this.category.title + ' | Lunna Teknik'
  }
}
</script>
