<template>
  <div>
    <highcharts :options="chartOptions" />
    <nav class="level">
      <!-- Performance List Dropdown -->
      <div class="level-left">
        <div class="dropdown is-hoverable is-up mr-2 my-2">
          <div class="dropdown-trigger">
            <button class="button is-primary is-outlined" aria-haspopup="true" aria-controls="dropdown-menu">
              <span v-if="activeCompareModel">{{ performanceDataList[activeCompareModel].name }}</span>
              <span v-else>Jämför med</span>
              <span class="icon is-small">
                <img src="/assets/icons/angle-up-solid.svg" width="10" height="16">
              </span>
            </button>
          </div>
          <div class="dropdown-menu" id="dropdown-menu" role="menu">
            <div class="dropdown-content">
              <a
                v-for="element in compareList"
                :key="element.key"
                class="dropdown-item"
                :class="{ 'is-active': activeCompareModel === element.key }"
                @click="activeCompareModel = element.key; renderGraph()"
              >
                {{ element.name }}
              </a>
            </div>
          </div>
        </div>
        <div class="is-hidden-tablet" style="width: 5rem;" />
        <!-- Attirbute Dropdown -->
        <div class="dropdown is-hoverable mr-2" v-if="activeCompareModel">
          <div class="dropdown-trigger">
            <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
              <span v-if="activeCopmareAttribute">{{ activeCopmareAttribute }}</span>
              <span v-else>Egenskaper</span>
              <span class="icon is-small">
                <img src="/assets/icons/angle-down-solid.svg" width="10" height="16">
              </span>
            </button>
          </div>
          <div class="dropdown-menu" id="dropdown-menu" role="menu">
            <div class="dropdown-content">
              <a
                v-for="element in performanceDataList[baseSeries].values[activeTempSerie].data"
                :key="element.name"
                class="dropdown-item"
                :class="{ 'is-active': activeCopmareAttribute === element.name }"
                @click="activeCopmareAttribute = element.name; renderGraph()"
              >
                {{ element.name }}
              </a>
            </div>
          </div>
        </div>
        <!-- Delete button -->
        <a
          class="tag is-delete"
          v-if="activeCompareModel"
          @click="clearCompare"
        />
      </div>
      <div class="level-right">
        <!-- Temperature list -->
        <div class="dropdown is-hoverable is-right is-up mr-2">
          <div class="dropdown-trigger">
            <button class="button is-primary is-outlined" aria-haspopup="true" aria-controls="dropdown-menu">
              <span>{{ activeTempSerie }}°C</span>
              <span class="icon is-small">
                <img src="/assets/icons/angle-up-solid.svg" width="10" height="16">
              </span>
            </button>
          </div>
          <div class="dropdown-menu" style="min-width: unset;" id="dropdown-menu" role="menu">
            <div class="dropdown-content">
              <a
                v-for="temperature in performanceDataList[baseSeries].values"
                :key="temperature.idx"
                class="dropdown-item"
                :class="{ 'is-active': activeTempSerie === temperature.idx }"
                @click="activeTempSerie = temperature.idx; renderGraph()"
              >
                {{ temperature.idx }}°C
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import PerformanceLT920 from '../../assets/performanceData/performanceLT920.json'
import PerformanceLT1230 from '../../assets/performanceData/performanceLT1230.json'
import PerformanceLT2130 from '../../assets/performanceData/performanceLT2130.json'

export default {
  name: 'PerformanceGraph',
  data () {
    return {
      isImageModalActive: false,
      activeTempSerie: 35,
      activeCompareModel: '',
      activeCopmareAttribute: '',
      performanceDataList: {
        performanceLT920: PerformanceLT920,
        performanceLT1230: PerformanceLT1230,
        performanceLT2130: PerformanceLT2130
      },
      attributes: {
        Värmekapacitet: {
          marker: {
            symbol: 'circle'
          },
          color: '#f03e3e',
          dashStyle: 'solid',
          unit: 'kW'
        },
        'Ingående Effekt': {
          marker: {
            symbol: 'diamond'
          },
          color: '#ffd43b',
          dashStyle: 'solid',
          unit: 'kW'
        },
        COP: {
          marker: {
            symbol: 'square'
          },
          color: '#37b24d',
          dashStyle: 'solid',
          unit: ''
        }
      },
      chartOptions: {
        chart: {
          type: 'line'
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        plotOptions: {
          line: {
            marker: {
              radius: 4,
              lineColor: '#666666',
              lineWidth: 1
            }
          }
        },
        tooltip: {
          formatter: function () {
            const row1 = this.x + '°'
            const series = this.points.map(point => {
              if (point.series.chart.userOptions.series[1].dashStyle === 'ShortDash') {
                return '<br><span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ' – ' + point.series.userOptions.compareLabel + ':<b> ' + point.y + ' ' + point.series.userOptions.unit + '</b>'
              } else {
                return '<br><span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ':<b> ' + point.y + ' ' + point.series.userOptions.unit + '</b>'
              }
            })
            return row1 + series
          },
          crosshairs: true,
          shared: true
        },
        xAxis: {
          title: {
            text: 'Temperatur (°C)'
          },
          labels: {
            formatter: function () { return this.value + '°' }
          }
        },
        yAxis: {
          title: {
            text: ''
          }
        },
        legend: {
          // Tweak to add compareLabel to legend.
          labelFormatter: function () {
            if (this.xAxis.chart.userOptions.series[1].dashStyle === 'ShortDash') {
              return this.userOptions.compareLabel + ' – ' + this.name
            } else {
              return this.name
            }
          }
        },
        series: [],
        credits: false
      }
    }
  },
  computed: {
    compareList () {
      const filteredList = {}
      for (const item in this.performanceDataList) {
        if (item !== this.baseSeries) {
          filteredList[item] = this.performanceDataList[item]
        }
      }
      return filteredList
    }
  },
  props: {
    baseSeries: {
      type: String,
      required: true
    }
  },
  mounted () {
    this.clearCompare()
    this.renderGraph()
  },
  methods: {
    renderGraph () {
      const defaultData = this.performanceDataList[this.baseSeries].values[this.activeTempSerie].data
      let mergedSeries = []

      // Set default graph attributes
      mergedSeries = defaultData.map(attributeItem => {
        return { ...attributeItem, ...this.attributes[attributeItem.name], compareLabel: this.performanceDataList[this.baseSeries].name }
      })

      if (this.activeCompareModel) {
        // Set default compare attribute
        if (!this.activeCopmareAttribute) {
          this.activeCopmareAttribute = 'Värmekapacitet'
        }

        let compareData = this.performanceDataList[this.activeCompareModel].values[this.activeTempSerie].data
        // Set default graph attributes
        compareData = compareData.map(attributeItem => {
          return { ...attributeItem, ...this.attributes[attributeItem.name], compareLabel: this.performanceDataList[this.activeCompareModel].name }
        })
        compareData.forEach(attributeItem => {
          attributeItem.marker.symbol = 'triangle'
          attributeItem.color = '#4a4a4a'
          attributeItem.dashStyle = 'ShortDash'
        })

        mergedSeries = mergedSeries.concat(compareData).filter(x => x.name === this.activeCopmareAttribute)
      }
      this.chartOptions.series = mergedSeries
    },
    clearCompare () {
      this.activeCompareModel = ''
      this.activeCopmareAttribute = ''
      this.chartOptions.series = this.performanceDataList[this.baseSeries].values[this.activeTempSerie].data
      this.renderGraph()
    }
  }
}
</script>
