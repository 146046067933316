<template>
  <table class="table is-hidden-desktop is-fullwidth is-hidden-tablet">
    <tbody>
      <tr>
        <th>&nbsp;</th>
        <td>
          <div class="my-0 mx-0 product-thumbnails">
            <product-image :img="product.img_list[0]" :dimensions="{ width: 64, height: 64 }" :htmlClass="'card-image-source'" />
          </div>
        </td>
      </tr>
      <tr>
        <th>Produkt</th>
        <td>{{ product.name }}</td>
      </tr>
      <tr>
        <th>Antal</th>
        <td>
          <input class="input" v-model="qty" type="number" min="1" max="150" style="width: 55px;" />
        </td>
      </tr>
      <tr>
        <th>Enhetspris</th>
        <td>{{ product.price | sek }}</td>
      </tr>
      <tr>
        <th>Pris</th>
        <td>{{ product.price * qty | sek }}</td>
      </tr>
      <tr>
        <th>Ta bort</th>
        <td>
          <a class="has-no-decoration" @click="removeItem">
            <span class="icon">
              <img src="/assets/icons/times-circle-regular.svg" width="16" height="16" />
            </span>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import ProductImage from '../blocks/ProductImage.vue'

export default {
  name: 'MobileCartTable',
  props: {
    sku: {
      type: String,
      required: true
    }
  },
  computed: {
    qty: {
      get () {
        return this.$store.state.cart.cartItems[this.sku]
      },
      set (value) {
        this.$store.dispatch('cart/updateQty', {
          sku: this.product.sku,
          qty: parseInt(value)
        })
      }
    },
    product () {
      return this.$store.state.inventory.products[this.sku]
    }
  },
  methods: {
    removeItem () {
      this.$store.dispatch('cart/removeItem', { sku: this.product.sku })
    }
  },
  components: {
    ProductImage
  }
}
</script>
