<template>
  <base-input
    type="email"
    name="email-address"
    :labelText="'E-post*'"
    :value="payment.emailAddress"
    @input.native="setPaymentProp(['emailAddress', $event.target.value])"
    @blur="$v.payment.emailAddress.$touch()"
    autocomplete="email"
    :validations="[
      {
        error: $v.payment.emailAddress.$error && !$v.payment.emailAddress.required,
        text: 'Obligatorisk uppgift'
      },
      {
        error: $v.payment.emailAddress.$error && !$v.payment.emailAddress.email,
        text: 'Vänligen ange en gilig e-postadress'
      }
    ]"
  />
</template>

<script>
import { mapState } from 'vuex'
import BaseInput from '../form/BaseInput.vue'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'PersonalDetails',
  validations: {
    payment: {
      emailAddress: {
        required,
        email
      }
    }
  },
  components: {
    BaseInput
  },
  computed: {
    ...mapState({
      payment: state => state.checkout.paymentDetails
    })
  },
  methods: {
    setPaymentProp (value) {
      this.$store.dispatch('checkout/setPaymentDetails', value)
    }
  }
}
</script>
