<template>
  <div class="column">
    <h2 class="title is-2" itemprop="name">{{ product.name }}</h2>
    <span class="subtitle is-3 has-text-weight-bold has-text-danger">{{ product.price | sek }}</span>
    <add-to-cart :product="product" />
    <shipping :product="product" />
    <hr />
    <div class="content mt-4" v-html="product.description" />
  </div>
</template>

<script>
import AddToCart from './AddToCart.vue'
import Shipping from './Shipping.vue'

export default {
  name: 'SingleProduct',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  components: {
    AddToCart,
    Shipping
  }
}
</script>
