<template>
  <div>
    <div class="columns is-centered has-text-centered">
      <div class="column is-half is-narrow">
        <button-full
          @click.native="prepareOrder"
          :button-class="'button is-danger is-medium mb-4 mt-1'"
          :class="{ 'is-loading': orderPending }"
          :disabled="formInvalid"
        >
          Slutför köp
        </button-full>
        <p v-if="connectionFailed" class="has-text-danger">
          Ett tekniskt fel uppstod. Vänligen testa att uppdatera webbsidan och försök igen.<br>
          Om problemet kvarstår, vänligen kontakta oss så lägger vi din order manuellt.
        </p>
        <p class="is-size-7">I samband med köp godkänner du också våra försäljningsvillkor.
        Du hittar dem under fliken information längst upp.</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Countries from '../../assets/countries.json'

import CartSummary from './CartSummary'
import { required, minLength, email, requiredIf } from 'vuelidate/lib/validators'
import ButtonFull from '../blocks/ButtonFull.vue'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'OrderReview',
  data () {
    return {
      countries: Countries,
      connectionFailed: false,
      orderPending: false
    }
  },
  validations: {
    extraPaymentOptions: {
      required: requiredIf(function () {
        return (this.paymentMethod.code === 'wasa_kredit')
      })
    },
    payment: {
      name: {
        required
      },
      country: {
        required
      },
      streetAddress: {
        required
      },
      zipCode: {
        required,
        minLength: minLength(4)
      },
      city: {
        required
      },
      phoneNumber: {
        required
      },
      emailAddress: {
        required,
        email
      }
    },
    shipping: {
      name: {
        required
      },
      country: {
        required
      },
      streetAddress: {
        required
      },
      zipCode: {
        required,
        minLength: minLength(3)
      },
      city: {
        required
      },
      phoneNumber: {
        required
      }
    }
  },
  computed: {
    ...mapState({
      shipping: state => state.checkout.shippingDetails,
      payment: state => state.checkout.paymentDetails,
      paymentMethod: state => state.cart.selectedPayment,
      cart: state => state.cart,
      products: state => state.inventory.products
    }),
    ...mapGetters({ totals: 'cart/totals' }),
    extraPaymentOptions () {
      return (this.paymentMethod.code === 'wasa_kredit' ? this.paymentMethod.salary : '')
    },
    formInvalid () {
      const conditionForm = this.shipping.sameAsBilling ? this.$v.payment.$invalid : this.$v.payment.$invalid || this.$v.shipping.$invalid
      const conditionEmail = this.$v.payment.emailAddress.$invalid
      const extraPaymentOptions = this.$v.extraPaymentOptions.$invalid
      return conditionForm || conditionEmail || extraPaymentOptions
    }
  },
  methods: {
    async prepareOrder () {
      this.orderPending = true
      if (this.shipping.sameAsBilling) {
        // Copy all existing keys from payment to shipping.
        for (const item in this.payment) {
          await this.$store.dispatch('checkout/setShippingDetails', [item, this.payment[item]])
        }
      }

      const cartProducts = []
      for (const sku in this.cart.cartItems) {
        cartProducts.push({
          sku: sku,
          name: this.products[sku].name,
          qty: this.cart.cartItems[sku],
          price: this.products[sku].price,
          discount: 0,
          description: ''
        })
      }

      const paymentMessage = this.cart.selectedPayment.messageFunc()
      this.cart.selectedPayment.message = paymentMessage

      const customerOrder = {
        cart: cartProducts,
        carrier: this.cart.selectedCarrier,
        payment: this.cart.selectedPayment,
        customer: {
          name: this.payment.name,
          contact: {
            email: this.payment.emailAddress,
            phone: this.payment.phoneNumber
          },
          vat: '',
          orgNumber: ''
        },
        shippingAddress: {
          name: this.shipping.name,
          company: this.shipping.company,
          street: this.shipping.streetAddress,
          city: this.shipping.city,
          postcode: this.shipping.zipCode,
          country: this.shipping.country,
          country_id: this.countries.find(c => c.name === this.shipping.country).code,
          telephone: this.shipping.phoneNumber
        },
        billingAddress: {
          name: this.payment.name,
          company: this.payment.company,
          street: this.payment.streetAddress,
          postcode: this.payment.zipCode,
          city: this.payment.city,
          country: this.payment.country,
          country_id: this.countries.find(c => c.name === this.payment.country).code,
          telephone: this.payment.phoneNumber
        },
        orderTotals: this.totals
      }
      this.placeOrder(customerOrder)
    },
    placeOrder (customerOrder) {
      const createOrderURL = `${this.$apiURL}/services/createOrder`
      axios.post(createOrderURL, { order: customerOrder })
        .then(resp => {
          this.orderPending = false
          this.$store.dispatch('checkout/setOrderPlaced', true)
          this.$store.dispatch('cart/clearCart')
        })
        .catch(_ => {
          this.orderPending = false
          this.connectionFailed = true
        })
    }
  },
  components: {
    ButtonFull
  },
  mixins: [CartSummary]

}
</script>
