<template>
  <div>
    <highcharts :options="chartOptions" />
    <div class="slidecontainer">
      <h3 class="title is-3">Vertikaljustering</h3>
      <p>Namn i VP: AUTO Set T. / Set Room Temp (Äldre styrpanel)</p>
      <input type="range" min="15" max="25" v-model="setRoomTemp" v-on:input="values">
      {{ setRoomTemp }}
    </div>
    <div class="slidecontainer">
      <h3 class="title is-3">Startjustering</h3>
      <p>Namn i VP: Initial BTW Temp</p>
      <input type="range" min="15" max="25" v-model="initialBTWTemp" v-on:input="values">
      {{ initialBTWTemp }}
    </div>
    <div class="slidecontainer">
      <h3 class="title is-3">Slutjustering</h3>
      <p>Namn i VP: Max BTW Temp</p>
      <input type="range" min="24" max="50" v-model="maxBTWTemp" v-on:input="values">
      {{ maxBTWTemp }}
    </div>
    <div class="content">
      Värmepumpen kan styra framledningen efter utomhustemperaturen.
      <br />
      Ändra på inställningarna ovan för att få fram de värden som passar dina behov.
    </div>
  </div>
</template>

<script>

export default {
  name: 'HeatingCurveInverter',
  data () {
    return {
      setRoomTemp: 20,
      initialBTWTemp: 20,
      maxBTWTemp: 50,
      chartOptions: {
        chart: {
          type: 'line'
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        plotOptions: {
          series: {
            animation: {
              duration: 0
            }
          },
          line: {
            marker: {
              radius: 4,
              lineColor: '#666666',
              lineWidth: 1
            }
          }
        },
        tooltip: {
          formatter: function () {
            const row1 = this.x
            const row2 = Math.round(this.y)
            return `Ute: ${row1}° <br />Framledning: ${row2}°`
          },
          crosshairs: true,
          shared: true
        },
        legend: {
          enabled: false
        },
        xAxis: {
          title: {
            text: 'Utomhustemperatur (°C)'
          },
          reversed: true,
          labels: {
            formatter: function () { return this.value + '°' }
          }
        },
        yAxis: {
          min: 10,
          max: 60,
          startOnTick: true,
          stopOnTick: true,
          tickInterval: 5,
          title: {
            text: 'Framledning (°C)'
          }
        },
        series: [{
          name: '',
          data: []
        }],
        credits: false
      }
    }
  },
  mounted () {
    this.values()
  },
  methods: {
    values () {
      const serie = []
      const setRoomTemp = Number(this.setRoomTemp)
      const initialBTWTemp = Number(this.initialBTWTemp)
      const maxBTWTemp = Number(this.maxBTWTemp)
      for (let x = -25; x < 26; x++) {
        let y = initialBTWTemp + ((maxBTWTemp - initialBTWTemp) / (35)) * (setRoomTemp - x)
        if (y > 55) y = 55
        if (y < 15) y = 15
        serie.push([x, y])
      }
      this.chartOptions.series[0].data = serie
    }
  }
}
</script>
