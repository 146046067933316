<template>
  <div class="container">
    <div class="columns">
      <div class="column is-one-third">
        <div class="box info-repo">
          <aside class="menu">
            <template v-for="node in info_repository_index">
              <p class="menu-label" :key="node.key">
                {{ node.key }}
              </p>
              <ul class="menu-list" :key="node.entries[0]">
                <li v-for="(subnode, index) in node.entries" :key="index">
                  <a :class="{ 'is-active': activeInfoRepositoryIndex === subnode }" @click="activeInfoRepositoryIndex = subnode" v-if="!Array.isArray(subnode)">{{ subnode }}</a>
                  <ul v-else>
                    <li v-for="subsubnode in subnode" :key="subsubnode">
                    <a :class="{ 'is-active': activeInfoRepositoryIndex === subsubnode }" @click="activeInfoRepositoryIndex = subsubnode">{{ subsubnode }}</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </template>
          </aside>
        </div>
      </div>
      <div class="column">
        <div class="box">
          <h2 class="title is-2">{{ activeInfoRepositoryIndex }}</h2>
          <div v-bind:is="info_repository[activeInfoRepositoryIndex]" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IRWW12TekniskSpec from './tekniskspec.vue'
import IRWW12Utförande from './utforande.vue'
import HeatingCurve from '../HeatingCurve.vue'
import HeatingCurve55 from '../HeatingCurve55.vue'

export default {
  name: 'IRWW12',
  data: function () {
    return {
      activeInfoRepositoryIndex: 'Teknisk Specifikation',
      info_repository_index: [
        {
          entries: [
            'Teknisk Specifikation',
            'Utförande',
            'Värmekurva',
            'Värmekurva (Vit styrpanel)'
          ],
          key: 'Information'
        }
      ],
      info_repository: {
        'Teknisk Specifikation': IRWW12TekniskSpec,
        Utförande: IRWW12Utförande,
        Värmekurva: HeatingCurve55,
        'Värmekurva (Vit styrpanel)': HeatingCurve
      }
    }
  },
  components: {
    IRWW12TekniskSpec,
    IRWW12Utförande,
    HeatingCurve,
    HeatingCurve55
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  }
}
</script>
