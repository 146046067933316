<template>
  <router-link :to="{ name: 'product', params: { sku: product.sku } }" >
    <div class="card product-card-transition">
      <div class="card-image">
        <product-image :img="product.img_list[0]" :dimensions="{ width: 0, height: 210 }" :htmlClass="'card-image-source'" />
      </div>
      <div class="card-content">
        <h4 class="title is-4 has-text-weight-bold my-0 mx-0">{{ product.name }}</h4>
        <hr class="my-1" />
          <span class="has-text-weight-bold is-size-5 has-text-danger">
            {{ product.price | sek }}
          </span>
        <div class="content">
          <p>{{ product.short_description }}</p>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import ProductImage from './ProductImage.vue'

export default {
  name: 'ProductTile',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  components: {
    ProductImage
  }
}
</script>
