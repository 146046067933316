<template>
  <div>
    <hr />
    <component :is="componentList[product.info_repository_key]" :product="product" />
  </div>
</template>

<script>
import IRLT920 from '../../../components/infoRepository/lt920/lt920.vue'
import IRLT1230 from '../../../components/infoRepository/lt1230/lt1230.vue'
import IRLT2130 from '../../../components/infoRepository/lt2130/lt2130.vue'
import IRWW6 from '../../../components/infoRepository/ww6/ww6.vue'
import IRWW12 from '../../../components/infoRepository/ww12/ww12.vue'
import IRWW18 from '../../../components/infoRepository/ww18/ww18.vue'

export default {
  name: 'InfoRepository',
  data () {
    return {
      componentList: {
        IRLT920,
        IRLT1230,
        IRLT2130,
        IRWW6,
        IRWW12,
        IRWW18
      }
    }
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  components: {
    IRLT920,
    IRLT1230,
    IRLT2130,
    IRWW6,
    IRWW12,
    IRWW18
  }
}
</script>
