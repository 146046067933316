var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-input',{attrs:{"type":"email","name":"email-address","labelText":'E-post*',"value":_vm.payment.emailAddress,"autocomplete":"email","validations":[
    {
      error: _vm.$v.payment.emailAddress.$error && !_vm.$v.payment.emailAddress.required,
      text: 'Obligatorisk uppgift'
    },
    {
      error: _vm.$v.payment.emailAddress.$error && !_vm.$v.payment.emailAddress.email,
      text: 'Vänligen ange en gilig e-postadress'
    }
  ]},on:{"blur":function($event){return _vm.$v.payment.emailAddress.$touch()}},nativeOn:{"input":function($event){return _vm.setPaymentProp(['emailAddress', $event.target.value])}}})
}
var staticRenderFns = []

export { render, staticRenderFns }