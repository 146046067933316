<template>
  <tr>
    <td>
      <div class="my-0 mx-0 product-thumbnails">
        <product-image :img="product.img_list[0]" :dimensions="{ width: 64, height: 64 }" :htmlClass="'card-image-source'" />
      </div>
    </td>
    <td>{{ product.name }}</td>
    <td>
      <input
        class="input"
        v-model="qty"
        type="number"
        min="1" max="150"
        style="width: 55px;"
      >
    </td>
    <td>{{ product.price | sek }}</td>
    <td>{{ product.price * qty | sek }}</td>
    <td>
      <a class="has-no-decoration" @click="removeItem">
        <span class="icon"><img src="/assets/icons/times-circle-regular.svg" width="16" height="16"></span>
      </a>
    </td>
  </tr>
</template>

<script>
import ProductImage from '../blocks/ProductImage.vue'

export default {
  name: 'Product',
  props: {
    sku: {
      type: String,
      required: true
    }
  },
  computed: {
    qty: {
      get () {
        return this.$store.state.cart.cartItems[this.sku]
      },
      set (value) {
        this.$store.dispatch('cart/updateQty', { sku: this.product.sku, qty: parseInt(value) })
      }
    },
    product () {
      return this.$store.state.inventory.products[this.sku]
    }
  },
  methods: {
    removeItem () {
      this.$store.dispatch('cart/removeItem', { sku: this.product.sku })
    }
  },
  components: {
    ProductImage
  }
}
</script>
