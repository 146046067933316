<template>
  <nav class="breadcrumb" aria-label="breadcrumbs">
    <ul itemscope itemtype="https://schema.org/BreadcrumbList">
      <li v-for="link in routes" :key="link.route_link"
          itemprop="itemListElement" itemscope
          itemtype="https://schema.org/ListItem">
        <router-link itemprop="item" :to="link.route_link">
          <span itemprop="name">{{ link.name }}</span>
          <meta itemprop="position" content="1">
        </router-link>
      </li>
      <li class="is-active"
          itemprop="itemListElement" itemscope
          itemtype="https://schema.org/ListItem">
        <a itemprop="item" href="#">
          <span itemprop="name">{{ activeRoute }}</span>
          <meta itemprop="position" content="2">
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  props: {
    routes: {
      type: Array,
      required: true
    },
    activeRoute: {
      type: String,
      required: true
    }
  }
}
</script>
