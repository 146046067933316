<template>
  <div class="field">
    <div class="control">
      <label class="checkbox">
        <input
          type="checkbox"
          :id="id"
          :checked="value"
          @keyup.enter="$emit('click')"
          @click="$emit('click')"
          @blur="$emit('blur')"
          @change="$emit('change')"
          :disabled="disabled"
        >
        <span
          :for="id"
        >
          <slot/>
        </span>
      </label>
      <template v-if="validation">
        <span
          v-if="validation.condition"
        >
          {{ validation.text }}
        </span>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    validation: {
      type: Object,
      required: false,
      default: () => {}
    },
    validations: {
      type: Array,
      required: false,
      default: () => []
    }
  }
}
</script>
