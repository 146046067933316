import Vue from 'vue'
import Router from 'vue-router'
import IndexPage from './views/IndexPage.vue'
import Checkout from './views/Checkout.vue'
import Info from './views/Info.vue'
import PageNotFound from './views/PageNotFound.vue'
import Category from './views/Category.vue'
import Product from './views/Product.vue'
import Inventory from './store/modules/inventory'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', name: 'index', component: IndexPage },
    { path: '/checkout', name: 'checkout', component: Checkout },
    { path: '/info', name: 'info', component: Info },
    { path: '/404', name: 'page-not-found', component: PageNotFound },
    {
      path: '/:uri',
      name: 'category',
      component: Category,
      beforeEnter: (to, _, next) => {
        if (Inventory.state.categories[to.params.uri]) {
          next()
        } else {
          next({ name: 'page-not-found' })
        }
      }
    },
    {
      path: '/artikel/:sku',
      name: 'product',
      component: Product,
      beforeEnter: (to, _, next) => {
        if (Inventory.state.products[to.params.sku]) {
          next()
        } else {
          next({ name: 'page-not-found' })
        }
      }
    }
  ]
})
