import localforage from 'localforage'

const cartStorage = localforage.createInstance({
  name: 'Lunna Teknik',
  storeName: 'cart'
})

const paymentDetailsStorage = localforage.createInstance({
  name: 'Lunna Teknik',
  storeName: 'paymentDetails'
})

const ledyerSessionStorage = localforage.createInstance({
  name: 'Lunna Teknik',
  storeName: 'ledyerSession'
})

export { cartStorage, paymentDetailsStorage, ledyerSessionStorage }
