<template>
  <div class="table-container">
    <table class="table is-hoverable is-striped is-fullwidth">
      <tbody>
        <tr>
          <th>Spänning / Fas</th>
          <td>400V / 3-Fas</td>
        </tr>
        <tr>
          <th>Minsta Säkring</th>
          <td>3x13A</td>
        </tr>
        <tr>
          <th>Effekt (Luft 7°C / Vatten 30°C)</th>
          <td>21,1kW</td>
        </tr>
        <tr>
          <th>Ljudnivå</th>
          <td>63dBA</td>
        </tr>
        <tr>
          <th>Kompressor</th>
          <td>Panasonic EVI Inverter</td>
        </tr>
        <tr>
          <th>Kondensor</th>
          <td>Plattvärmeväxlare</td>
        </tr>
        <tr>
          <th>Economizer</th>
          <td>Plattvärmeväxlare</td>
        </tr>
        <tr>
          <th>Reglering Förångare</th>
          <td>Elektronisk Expansionsventil</td>
        </tr>
        <tr>
          <th>Reglering Economizer</th>
          <td>Elektronisk Expansionsventil</td>
        </tr>
        <tr>
          <th>Vatttenflödesvolym</th>
          <td>
            1,3m
            <sup>3</sup>/h
          </td>
        </tr>
        <tr>
          <th>Högsta Kondenseringstemperatur</th>
          <td>55°C</td>
        </tr>
        <tr>
          <th>Anslutningsgänga</th>
          <td>R25</td>
        </tr>
        <tr>
          <th>Anslutningsrör (Vatten)</th>
          <td>Minst 28mm koppar / 25mm PEX</td>
        </tr>
        <tr>
          <th>Cirkulationspump</th>
          <td>Inbyggd 10/25 180mm A-klass</td>
        </tr>
        <tr>
          <th>Köldmedium</th>
          <td>R32</td>
        </tr>
        <tr>
          <th>Köldmediummängd</th>
          <td>3850g</td>
        </tr>
        <tr>
          <th>Vattenfilter</th>
          <td>Extern filterball med magnet (Medföljer)</td>
        </tr>
        <tr>
          <th>Vikt</th>
          <td>150kg</td>
        </tr>
        <tr>
          <th>Mått (LxBxH)</th>
          <td>1105x425x1245mm</td>
        </tr>
        <tr>
          <th>Garanti</th>
          <td>2 år</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'IRLT2130TekniskSpec'
}
</script>
