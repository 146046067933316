<template>
  <div id="home">
    <section class="section is-main">
      <div class="container is-main">
        <div class="columns">
          <div class="column">
            <h1 class="title is-1">
              Värmepumpar
            </h1>
            <h3 class="subtitle is-3">
              EVI · On/Off · Vatten/Vatten
            </h3>
            <div class="content">
              <p>
                Lunna Teknik säljer värmepumpar till låga priser och med hög kvalité.
                Vi har ett antal modeller för att passa många olika behov. Tryck på kategorin Värmepumpar längst upp för att läsa mer.<br>
                Du är alltid välkommen att ringa oss på 073-392 39 65, maila <a href="mailto:info@lunnateknik.se">info@lunnateknik.se</a>
                eller använda kontaktformuläret i menyn.
              </p>
            </div>
          </div>
          <div class="column is-half ">
            <div class="is-product-image">
              <figure class="image">
                <product-image class="index-image" :img="imgList[0]" />
              </figure>
            </div>
          </div>
        </div>
        <hr>
        <div class="columns">
          <div class="column">
            <h1 class="title is-1">
              TB-Mini
            </h1>
            <div class="content">
              <p>
                Vi säljer tillbehör till TB-Mini. Tryck på kategorin TB-Mini längst upp för att se vårt sortiment.
              </p>
            </div>
          </div>
          <div class="column is-half">
            <div class="is-product-image">
              <figure class="image">
                <product-image class="index-image" :img="imgList[1]" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ProductImage from '../components/blocks/ProductImage.vue'

export default {
  name: 'IndexPage',
  data () {
    return {
      imgList: [
        { img_base: '/assets/images/index/evi/EVI1811' },
        { img_base: '/assets/images/index/tb/TB_5_Marbenius' }
      ]
    }
  },
  created () {
    document.title = 'Index | Lunna Teknik'
  },
  components: {
    ProductImage
  }
}
</script>
