<template>
  <div id="checkout">
    <section class="section is-main">
      <div class="container is-main checkout">
        <h1 v-show="!orderPlaced" class="title is-1">Slutför Beställning</h1>
        <h1 v-show="orderPlaced" class="title is-1">Tack för din beställning!</h1>
        <p class="has-text-centered pb-4" v-if="cartQty === 0">Din kundvagn är tom</p>
        <cart-summary v-if="cartQty > 0" />
          <div class="pt-5" v-if="!orderPlaced && cartQty > 0">
            <p class="has-text-centered pb-4">Välj om du handlar som privatperson eller företagskund</p>
            <div class="tabs is-toggle is-centered">
              <ul>
                <!-- Privat tab -->
                <li :class="{ 'is-active': customerType === 'private' }" @click="setCustomerType('private')">
                  <a>
                    <span>Privat</span>
                  </a>
                </li>
                <!-- Företag tab -->
                <li :class="{ 'is-active': customerType === 'business' }" @click="setCustomerType('business')">
                  <a>
                    <span>Företag</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <section class="section">
            <div v-if="!orderPlaced && customerType === 'private'">
              <shipping />
              <payment />
              <order-review />
            </div>
            <BusinessCheckout v-if="customerType === 'business'" />
            <thank-you-page v-show="orderPlaced" />
          </section>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { decode } from 'url-safe-base64'

import Shipping from '../components/checkout/Shipping.vue'
import Payment from '../components/checkout/Payment.vue'
import OrderReview from '../components/checkout/OrderReview.vue'
import CartSummary from '../components/checkout/CartSummary.vue'
import ThankYouPage from '../components/checkout/ThankYouPage.vue'
import BusinessCheckout from '../components/checkout/businessCheckout.vue'

export default {
  name: 'Checkout',
  computed: {
    ...mapState({ orderPlaced: state => state.checkout.orderPlaced }),
    ...mapGetters({ cartQty: 'cart/totalQuantity' })
  },
  data () {
    return {
      customerType: ''
    }
  },
  // watch: {
  //   cartQty: function (newQty) {
  //     if (newQty === 0 && !this.orderPlaced) {
  //       this.$router.push('/')
  //     }
  //   }
  // },
  created () {
    document.title = 'Slutför Beställning | Lunna Teknik'
    const encodedCart = this.$route.query.cart
    if (encodedCart) {
      const cartData = this.decodeCartData(encodedCart)
      if (cartData) {
        this.$store.dispatch('cart/clearCart', cartData)
        for (const item of cartData.items) {
          this.$store.dispatch('cart/addItem', { sku: item.sku, qty: item.quantity })
        }
        if (cartData.discount) {
          this.$store.dispatch('cart/applyDiscount', cartData.discount)
        }
      }
    }
    // if (this.cartQty === 0) {
    //   this.$router.push('/')
    // }
  },
  methods: {
    setCustomerType (type) {
      this.customerType = type
    },
    decodeCartData (encodedData) {
      let jsonStr = ''
      try {
        jsonStr = decode(encodedData)
        jsonStr = atob(encodedData)
      } catch (error) {
        console.error('Error decoding cart data:', error)
        return null
      }

      let cartData = null
      try {
        cartData = JSON.parse(jsonStr)
      } catch (error) {
        console.error('Error parsing cart data:', error)
      }
      return cartData
    }
  },
  beforeDestroy () {
    this.$store.dispatch('checkout/setOrderPlaced', false)
  },
  components: {
    Shipping,
    Payment,
    OrderReview,
    CartSummary,
    ThankYouPage,
    BusinessCheckout
  }
}
</script>
