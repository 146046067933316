<template>
  <div class="column">
    <h2 class="title is-2" itemprop="name">{{ product.name }}</h2>
    <table class="table responsive-table is-fullwidth table-align-middle">
      <tbody>
        <tr v-for="(linkedProduct, index) in product.linked_products" :key="index">
          <td>
            <div class="my-0 mx-0 product-thumbnails">
              <product-image :img="products[linkedProduct].img_list[0]" :dimensions="{ width: 64, height: 64 }" :htmlClass="'card-image-source'" />
            </div>
          </td>
          <td>{{ products[linkedProduct].name }}</td>
          <td v-if="'sale_price' in products[linkedProduct]">
            <span class="prev-price">{{ products[linkedProduct].price | sek }}</span>
            <br />
            <span class="price">
              {{ products[linkedProduct].sale_price | sek }} <br />
              {{ products[linkedProduct].sale_message }}
            </span>
          </td>
          <td v-else><span class="has-text-weight-bold has-text-danger" >{{ products[linkedProduct].price | sek }}</span></td>
          <td><add-to-cart :product="products[linkedProduct]" />
          </td>
        </tr>
      </tbody>
    </table>
    <shipping :product="product" />
    <div class="content mt-4" v-html="product.description" />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import AddToCart from './AddToCart.vue'
import Shipping from './Shipping.vue'
import ProductImage from '../../../components/blocks/ProductImage.vue'

export default {
  name: 'GroupedProduct',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      products: state => state.inventory.products
    })
  },
  components: {
    AddToCart,
    Shipping,
    ProductImage
  }
}
</script>
