import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

import Vuelidate from 'vuelidate'
import VueHighcharts from 'vue-highcharts'
import {
  cartStorage,
  paymentDetailsStorage,
  ledyerSessionStorage
} from './store/localPersist'

if (process.env.NODE_ENV === 'development') {
  Vue.prototype.$apiURL = 'http://192.168.1.136:3050/api'
  Vue.prototype.$ledyerScriptURL = 'https://checkout.sandbox.ledyer.com/bootstrap.js'
}
if (process.env.NODE_ENV === 'production') {
  Vue.prototype.$apiURL = 'https://lunnateknik.se/api'
  Vue.prototype.$ledyerScriptURL = 'https://checkout.live.ledyer.com/bootstrap.js'
}

Vue.use(Vuelidate)
Vue.use(VueHighcharts)

Vue.filter('sek', (value) =>
  value.toLocaleString('sv-SE', {
    style: 'currency',
    currency: 'SEK',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  })
)

const cartPromise = cartStorage.iterate(function (value, key) {
  store.dispatch('cart/addItem', { sku: key, qty: value })
})
const paymentPromise = paymentDetailsStorage.iterate(function (value, key) {
  store.dispatch('checkout/setPaymentDetailsLF', [key, value])
})
const ledyerPromise = ledyerSessionStorage.getItem('session').then((value) => {
  if (!value) {
    return
  }
  if (!Object.hasOwn(value, 'sessionId') && !Object.hasOwn(value, 'expiresAt')) {
    ledyerSessionStorage.removeItem('session')
    return
  }
  try {
    axios.get(`${Vue.prototype.$apiURL}/services/getSessionState/${value.sessionId}`).then((response) => {
      const { state } = response.data
      if (state !== 'open') {
        ledyerSessionStorage.removeItem('session')
      }
    })
  } catch (error) {
    console.log('Order session error ' + error)
  }
  const expiration = new Date(value.expiresAt)
  expiration.setDate(expiration.getDate() - 1)
  if (expiration < new Date()) {
    ledyerSessionStorage.removeItem('session')
    return
  }
  store.dispatch('checkout/setLedyerSessionLF', value)
})
const lfPromises = [cartPromise, paymentPromise, ledyerPromise]
Vue.config.productionTip = false

Promise.all(lfPromises).then(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App)
  }).$mount('#app')
})
