<template>
  <div class="navbar-start">
    <router-link
      :key="category.id"
      v-for="category of categories"
      class="navbar-item"
      @click.native="setMobileMenu(false)"
      :to="category.url"
    >
      {{ category.title }}
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CategoryMenu',
  computed: {
    ...mapState({ categories: state => state.inventory.categories })
  },
  methods: {
    setMobileMenu (value) {
      this.$store.dispatch('ui/setMobileMenu', value)
    }
  }
}
</script>
