import Vue from 'vue'
import Vuex from 'vuex'

import checkout from './modules/checkout'
import cart from './modules/cart'
import ui from './modules/ui'
import inventory from './modules/inventory'

const debug = process.env.NODE_ENV !== 'production'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    checkout,
    cart,
    inventory,
    ui
  },
  strict: debug
})
