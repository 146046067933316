<template>
  <div class="table-container">
    <table class="table is-hoverable is-striped is-fullwidth">
      <tbody>
        <tr>
          <th>Spänning / Fas</th>
          <td>400V / 3-Fas</td>
        </tr>
        <tr>
          <th>Minsta Säkring</th>
          <td>3x10A</td>
        </tr>
        <tr>
          <th>Vätska 10°C / Vatten 35°C</th>
          <td>In: 2,6kW / Ut: 12,2kW / COP 4,7</td>
        </tr>
        <tr>
          <th>Vätska 0°C / Vatten 35°C</th>
          <td>In: 2,6kW / Ut: 11,4kW / COP 4,3</td>
        </tr>
        <tr>
          <th>Vätska 0°C / Vatten 55°C</th>
          <td>In: 3,8kW / Ut: 9,5kW / COP 2,5</td>
        </tr>
        <tr>
          <th>Ljudnivå</th>
          <td>42dB vid 3m</td>
        </tr>
        <tr>
          <th>Kompressor</th>
          <td>Sanyo Scroll (Fast hastighet)</td>
        </tr>
        <tr>
          <th>Kondensor</th>
          <td>Plattvärmeväxlare</td>
        </tr>
        <tr>
          <th>Förångare</th>
          <td>Plattvärmeväxlare</td>
        </tr>
        <tr>
          <th>Reglering Förångare</th>
          <td>Elektronisk Expansionsventil</td>
        </tr>
        <tr>
          <th>Vatttenflödesvolym</th>
          <td>
            1,1m
            <sup>3</sup>/h
          </td>
        </tr>
        <tr>
          <th>Högsta tilloppstemperatur</th>
          <td>55°C</td>
        </tr>
        <tr>
          <th>Anslutningsgänga</th>
          <td>R25</td>
        </tr>
        <tr>
          <th>Anslutningsrör (Vatten)</th>
          <td>Minst 22mm koppar / 20mm PEX</td>
        </tr>
        <tr>
          <th>Cirkulationspump (Värmebärare)</th>
          <td>Inbyggd 7/25 180mm A-klass</td>
        </tr>
        <tr>
          <th>Cirkulationspump (Köldbärare)</th>
          <td>Extern 6/25 180mm A-klass (Medföljer)</td>
        </tr>
        <tr>
          <th>Köldmedium</th>
          <td>R410A</td>
        </tr>
        <tr>
          <th>Köldmediummängd</th>
          <td>1500g</td>
        </tr>
        <tr>
          <th>Vattenfilter</th>
          <td>Extern filterball med magnet (Medföljer)</td>
        </tr>
        <tr>
          <th>Vikt</th>
          <td>80kg</td>
        </tr>
        <tr>
          <th>Mått (LxBxH)</th>
          <td>580x600x764mm</td>
        </tr>
        <tr>
          <th>Garanti</th>
          <td>2 år</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'IRWW12TekniskSpec'
}
</script>
