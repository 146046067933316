<template>
  <div>
    <highcharts :options="chartOptions" />
    <div class="slidecontainer">
      <h3 class="title is-3">Lutning</h3>
      <p>Slope set</p>
      <input type="range" min="24" max="50" v-model="slopeSet" v-on:input="values">
      {{ slopeSet }}
    </div>
    <div class="slidecontainer">
      <h3 class="title is-3">Förskjutning</h3>
      <p>Translation set</p>
      <input type="range" min="0" max="30" v-model="translationSet" v-on:input="values">
      {{ translationSet }}
    </div>
    <div class="content">
      Värmepumpen kan styra framledningen efter utomhustemperaturen.
      <br />
      Ändra på lutning och förskjutning för att få fram de värden som passar dina behov.
    </div>
  </div>
</template>

<script>

export default {
  name: 'HeatingCurve',
  data () {
    return {
      slopeSet: 30,
      translationSet: 15,
      chartOptions: {
        chart: {
          type: 'line'
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        plotOptions: {
          series: {
            animation: {
              duration: 0
            }
          },
          line: {
            marker: {
              radius: 4,
              lineColor: '#666666',
              lineWidth: 1
            }
          }
        },
        tooltip: {
          formatter: function () {
            const row1 = this.x
            const row2 = Math.round(this.y)
            return `Ute: ${row1}° <br />Framledning: ${row2}°`
          },
          crosshairs: true,
          shared: true
        },
        legend: {
          enabled: false
        },
        xAxis: {
          title: {
            text: 'Utomhustemperatur (°C)'
          },
          reversed: true,
          labels: {
            formatter: function () { return this.value + '°' }
          }
        },
        yAxis: {
          min: 10,
          max: 60,
          startOnTick: true,
          stopOnTick: true,
          tickInterval: 5,
          title: {
            text: 'Framledning (°C)'
          }
        },
        series: [{
          name: '',
          data: []
        }],
        credits: false
      }
    }
  },
  mounted () {
    this.values()
  },
  methods: {
    values () {
      const serie = []
      const slope = Number(this.slopeSet)
      const translation = Number(this.translationSet)
      for (let x = -25; x < 26; x++) {
        let Ts = ((((slope - 18) * (18 - x)) / (18)) + 18 + translation)
        if (Ts >= 55) Ts = 55
        if (Ts < 20) Ts = 20
        serie.push([x, Ts])
      }
      this.chartOptions.series[0].data = serie
    }
  }
}
</script>
