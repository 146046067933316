<template>
  <div class="columns is-multiline is-mobile">
    <div class="column is-half">
      <figure class="image is-product-image">
        <product-image :img="imgList[0]" />
      </figure>
    </div>
    <div class="column is-half">
      <figure class="image is-product-image">
        <product-image :img="imgList[1]" />
      </figure>
    </div>
    <div class="column is-half">
      <figure class="image is-product-image">
        <product-image :img="imgList[2]" />
      </figure>
    </div>
    <div class="column is-half">
      <figure class="image">
        <img style="max-height: 340px;" src="/assets/energyLabels/lt_2130.svg" />
      </figure>
    </div>
  </div>
</template>

<script>
import ProductImage from '../../blocks/ProductImage.vue'

export default {
  name: 'IRLT2130Utförande',
  data () {
    return {
      imgList: [
        { img_base: '/assets/images/infoRepository/LT2130/front1' },
        { img_base: '/assets/images/infoRepository/LT2130/front2' },
        { img_base: '/assets/images/infoRepository/LT2130/rear1' }
      ]
    }
  },
  components: {
    ProductImage
  }
}
</script>
