import Vue from 'vue'
import { cartStorage } from '../localPersist'
import store from '..'
import { v4 as uuidv4 } from 'uuid'
import ShippingMethods from '../../assets/shippingMethods.json'
import PaymentMethods from '../../assets/paymentMethods.json'

const state = {
  cartItems: {},
  lastProduct: '',
  shippingMethods: ShippingMethods,
  paymentMethods: PaymentMethods,
  availablePaymentMethods: {},
  availableCarriers: [],
  selectedCarrier: {},
  selectedPayment: PaymentMethods.invoice,
  cartUUID: '',
  discount: 0
}

// Add a listener for the storage event to sync the cart across tabs
window.addEventListener('storage', async (event) => {
  if (event.key === 'cartVersion') {
    try {
      // Fetch the updated cart data from localforage when the storage event is triggered
      const updatedCart = {}
      await cartStorage.iterate((value, key) => {
        updatedCart[key] = value
      })
      store.commit('cart/replaceCart', updatedCart)
    } catch (error) {
      console.error('Error fetching updated cart:', error)
    }
  }
})

const getters = {
  totalQuantity: (state) => {
    const cart = state.cartItems
    return Object.keys(cart).reduce((accumulator, p) => accumulator + parseInt(cart[p]), 0)
  },
  productsInCart: (state) => {
    return Object.keys(state.cartItems)
  },
  cartUUID: () => {
    return state.cartUUID
  },
  totals: (state, _, rootState) => {
    const cart = state.cartItems
    const products = rootState.inventory.products
    const subtotal = Object.keys(cart).reduce((accumulator, p) => accumulator + (parseInt(cart[p]) * products[p].price), 0)
    const discountValue = state.discount
    return {
      subtotalInclTax: {
        code: 'subtotalInclTax',
        title: 'Delsumma inkl. moms',
        value: subtotal
      },
      discount: {
        code: 'discount',
        title: 'Rabatt',
        value: discountValue
      },
      shipping: {
        code: 'shipping',
        title: state.selectedCarrier.carrier_title,
        value: state.selectedCarrier.price_incl_tax
      },
      grand_total: {
        code: 'grand_total',
        title: 'Totalt',
        value: subtotal + discountValue + state.selectedCarrier.price_incl_tax + state.selectedPayment.costInclTax
      }
    }
  }
}

const actions = {
  addItem ({ commit, state, dispatch }, { sku, qty }) {
    localStorage.setItem('cartVersion', Date.now().toString())
    commit('cartUUIDRefresh')
    commit('clearDiscount')
    if (!(sku in state.cartItems)) {
      commit('addItem', { sku, qty })
      dispatch('sortShipping')
      cartStorage.setItem(sku, qty)
    } else {
      const currentQty = state.cartItems[sku]
      const newQty = currentQty + qty
      dispatch('updateQty', { sku, qty: newQty })
      cartStorage.setItem(sku, newQty)
    }
  },
  updateQty ({ commit }, { sku, qty }) {
    localStorage.setItem('cartVersion', Date.now().toString())
    commit('cartUUIDRefresh')
    commit('updateQty', { sku, qty })
    commit('clearDiscount')
    cartStorage.setItem(sku, qty)
  },
  clearCart ({ commit, dispatch }) {
    localStorage.setItem('cartVersion', Date.now().toString())
    commit('cartUUIDRefresh')
    commit('clearCart')
    commit('clearDiscount')
    dispatch('sortShipping')
    cartStorage.clear()
  },
  removeItem ({ commit, dispatch }, { sku }) {
    localStorage.setItem('cartVersion', Date.now().toString())
    commit('cartUUIDRefresh')
    commit('removeItem', sku)
    dispatch('sortShipping')
    commit('clearDiscount')
    cartStorage.removeItem(sku)
  },
  setCarrier ({ commit }, value) {
    commit('setCarrier', value)
  },
  setPaymentMethod ({ commit }, value) {
    commit('setPaymentMethod', value)
  },
  setSelectedPaymentOption ({ commit }, { option, value }) {
    commit('setSelectedPaymentOption', { option, value })
  },
  sortPaymentMethods ({ state, rootGetters, getters, commit }) {
    const paymentCountry = rootGetters['checkout/paymentCountry']
    const availPayments = {}
    for (const key in state.paymentMethods) {
      const countryCheck = state.paymentMethods[key].availableCountries.includes(paymentCountry)
      const sumCheck = state.paymentMethods[key].minTotalSum < getters.totals.grand_total.value
      if (countryCheck && sumCheck) {
        availPayments[key] = state.paymentMethods[key]
      }
    }
    if (!(state.selectedPayment.code in availPayments)) {
      commit('setPaymentMethod', 'invoice')
    }
    commit('sortPaymentMethods', availPayments)
  },
  sortShipping ({ commit, state, rootState }) {
    const cart = state.cartItems
    const products = rootState.inventory.products
    const methodsInCart = Object.keys(cart)
      .map(x => products[x].shipping_methods)
      .flat()
      .map(y => state.shippingMethods[y])
      .filter((item, idx, arr) => arr.indexOf(item) === idx)

    const priorityLevel = methodsInCart
      .reduce((max, element) => element.priority > max ? element.priority : max, 0)

    const availableMethods = methodsInCart
      .filter(method => method.priority === priorityLevel)

    const selectedMethod = availableMethods
      .reduce((max, element) => element.price_incl_tax > max ? element.price_incl_tax : max, availableMethods[0])

    commit('setShipping', { sShipping: selectedMethod, aShipping: availableMethods })
  },
  setLastProduct ({ commit, rootState }, { sku }) {
    const product = rootState.inventory.products[sku]
    commit('setLastProduct', product.name)
  },
  applyDiscount ({ commit }, discount) {
    commit('setDiscount', discount)
  },
  removeDiscount ({ commit }) {
    commit('clearDiscount')
  }
}

const mutations = {
  addItem (state, { sku, qty }) {
    Vue.set(state.cartItems, sku, qty)
  },
  updateQty (state, { sku, qty }) {
    state.cartItems[sku] = qty
  },
  clearCart (state) {
    state.cartItems = {}
  },
  cartUUIDRefresh (state) {
    state.cartUUID = uuidv4()
  },
  removeItem (state, sku) {
    Vue.delete(state.cartItems, sku)
  },
  setCarrier (state, value) {
    state.selectedCarrier = value
  },
  setPaymentMethod (state, value) {
    state.selectedPayment = state.paymentMethods[value]
  },
  sortPaymentMethods (state, availPayments) {
    state.availablePaymentMethods = availPayments
  },
  setSelectedPaymentOption (state, { option, value }) {
    state.selectedPayment[option] = value
  },
  setShipping (state, { sShipping, aShipping }) {
    state.availableCarriers = aShipping
    state.selectedCarrier = sShipping
  },
  setLastProduct (state, productName) {
    state.lastProduct = productName
  },
  setDiscount (state, discount) {
    state.discount = discount
  },
  clearDiscount (state) {
    state.discount = 0
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
