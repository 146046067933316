<template>
  <!-- Navbar -->
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="container">
      <!-- navbar items, navbar burger... -->
      <div class="navbar-brand">
        <router-link to="/" class="navbar-item">
          <img
            width="220px"
            src="/assets/lunnateknik-logo.svg"
            alt="Lunna Teknik"
          >
        </router-link>
        <router-link v-if="totalQuantity > 0" class="navbar-item is-hidden-desktop" :to="{ name: 'checkout'}">
          Kundvagn<span class="has-text-danger">({{ totalQuantity }})</span><span class="icon"><svg width="18" height="16" aria-hidden="true" data-prefix="fas" data-icon="shopping-cart" class="svg-inline--fa fa-shopping-cart fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M528.12 301.319l47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z" /></svg></span>
        </router-link>
        <a role="button" class="navbar-burger" :class="{ 'is-active': mobileMenuActive }" @click="setMobileMenu(!mobileMenuActive)" aria-label="menu" aria-expanded="false">
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </a>
      </div>
      <!-- navbar start, navbar end -->
      <div class="navbar-menu" :class="{ 'is-active': mobileMenuActive }">
        <category-list />
        <div class="navbar-end">
          <router-link
            class="navbar-item"
            :to="{ name: 'info' }"
          >
            Information&nbsp;<span class="icon"><svg width="20" height="16" aria-hidden="true" data-prefix="fas" data-icon="chalkboard-teacher" class="svg-inline--fa fa-chalkboard-teacher fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M208 352c-2.39 0-4.78.35-7.06 1.09C187.98 357.3 174.35 360 160 360c-14.35 0-27.98-2.7-40.95-6.91-2.28-.74-4.66-1.09-7.05-1.09C49.94 352-.33 402.48 0 464.62.14 490.88 21.73 512 48 512h224c26.27 0 47.86-21.12 48-47.38.33-62.14-49.94-112.62-112-112.62zm-48-32c53.02 0 96-42.98 96-96s-42.98-96-96-96-96 42.98-96 96 42.98 96 96 96zM592 0H208c-26.47 0-48 22.25-48 49.59V96c23.42 0 45.1 6.78 64 17.8V64h352v288h-64v-64H384v64h-76.24c19.1 16.69 33.12 38.73 39.69 64H592c26.47 0 48-22.25 48-49.59V49.59C640 22.25 618.47 0 592 0z" /></svg></span>
          </router-link>
          <a class="navbar-item" @click="setContactForm(true)">
            Kontakt&nbsp;<span class="icon"><svg width="16" height="16" aria-hidden="true" data-prefix="fas" data-icon="paper-plane" class="svg-inline--fa fa-paper-plane fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z" /></svg></span>
          </a>
          <microcart-icon class="is-hidden-touch" />
        </div>
      </div>
    </div>
    <contact-form />
  </nav>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import MicrocartIcon from './MicrocartIcon.vue'
import CategoryList from './CategoryList.vue'
import ContactForm from '../blocks/ContactForm.vue'

export default {
  name: 'Header',
  computed: {
    ...mapState({
      mobileMenuActive: state => state.ui.mobileMenuActive
    }),
    ...mapGetters({
      totalQuantity: 'cart/totalQuantity'
    })
  },
  methods: {
    setContactForm (value) {
      this.$store.dispatch('ui/setContactForm', value)
    },
    setMobileMenu (value) {
      this.$store.dispatch('ui/setMobileMenu', value)
    }
  },
  components: {
    CategoryList,
    MicrocartIcon,
    ContactForm
  }
}
</script>
