<template>
  <div>
    <table v-for="(item, index) in creditLengths" :key="index" class='table is-hidden-desktop is-fullwidth is-size-6 no-wrap'>
      <tbody>
          <tr>
            <th>Löptid</th>
            <td v-if="selectable">
              <div class='control'>
                <label class='radio'>
                  <input type='radio' name='credit-length-mobile' @input="setOption('creditLength', item)" :checked="wasa_method.creditLength === item" :value="item" />
                  {{ item }}&nbsp;månader
                </label>
              </div>
            </td>
            <td v-else>
              {{ item }}&nbsp;månader
            </td>
          </tr>
          <tr>
            <th>Månadskostnad</th>
            <td>{{ totalPrice / item | sek }}</td>
          </tr>
          <tr>
            <th>Totalkostnad</th>
            <td>{{ totalPrice | sek }}</td>
          </tr>
      </tbody>
    </table>
    <table class='table is-fullwidth is-hidden-touch is-size-6 no-wrap'>
      <thead>
        <tr>
          <th>Löptid</th>
          <th>Månadskostnad</th>
          <th>Kampanjavgift</th>
          <th>Totalkostnad</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for='(item, index) in creditLengths' :key='index'>
          <td v-if="selectable">
            <div class='control'>
              <label class='radio'>
                <input type='radio' name='credit-length' @input="setOption('creditLength', item)" :checked="wasa_method.creditLength === item" :value="item" />
                {{ item }}&nbsp;månader
              </label>
            </div>
          </td>
          <td v-else>
            {{ item }}&nbsp;månader
          </td>
          <td>{{ (totalPrice / item) + adminFee | sek }}</td>
          <td>{{ startFee[item] | sek }}</td>
          <td>{{ totalAmount(item, startFee[item]) | sek }}</td>
        </tr>
      </tbody>
    </table>
      Administrativ avgift {{ adminFee | sek }} ingår i månadskostnaden.
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'wasaKreditRantefri',
  computed: {
    ...mapState({
      wasa_method: (state) => state.cart.selectedPayment
    })
  },
  created () {
    const defaultCondition = !this.creditLengths.includes(this.wasa_method.creditLength)
    if (defaultCondition) {
      this.setOption('creditLength', this.creditLengths[0])
    }
    this.$store.dispatch('cart/setSelectedPaymentOption', { option: 'messageFunc', value: this.createMessage })
  },
  methods: {
    setOption (option, value) {
      this.$store.dispatch('cart/setSelectedPaymentOption', { option: option, value: value })
    },
    totalAmount (length, startFee) {
      return this.totalPrice + this.adminFee * length + startFee
    },
    createMessage () {
      const formattedSalary = parseInt(this.wasa_method.salary).toLocaleString('sv-SE', { style: 'currency', currency: 'SEK', maximumFractionDigits: 0, minimumFractionDigits: 0 })
      const signMethod = this.wasa_method.mobiltBankID ? 'Mobilt BankID' : 'Distansavtal'
      return `${this.wasa_method.selectedOption}<br />${this.wasa_method.creditLength} månader<br />Angiven månadsinkomst: ${formattedSalary}<br />Signering: ${signMethod}`
    }
  },
  props: {
    totalPrice: {
      type: Number,
      required: true
    },
    selectable: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      creditLengths: [6, 12, 24],
      startFee: {
        6: 395,
        12: 495,
        24: 595
      },
      adminFee: 45
    }
  }
}
</script>
