<template>
  <div>
    <div>
      <p><strong>Faktura</strong></p>
      <ul>
        <li>14 dagar</li>
        <li><strong>{{ cartTotal.grand_total.title }}: </strong><span class="has-text-danger">{{ cartTotal.grand_total.value | sek }}</span></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Billogram',
  computed: {
    cartTotal () {
      return this.$store.getters['cart/totals']
    },
    paymentCountry () {
      return this.$store.getters['checkout/paymentCountry']
    }
  },
  methods: {
    createMessage () {
      let message = ''
      if (this.paymentCountry === 'Sverige') {
        message = '14 dagar'
      } else {
        message = 'We require all foreign payments to made in advance. We will send the invoice after you have placed your order.'
      }
      return message
    }
  },
  mounted () {
    this.$store.dispatch('cart/setSelectedPaymentOption', { option: 'messageFunc', value: this.createMessage })
  }
}
</script>
