<template>
  <div class="has-text-centered">
    <p class="h4">
      Du kommer att få ett mail som bekräftelse på din beställning.<br>
      <br>
      Vi behandlar alla beställningar så snabbt vi kan. Du kommer att få ett till mail när din order har blivit skickad.<br>
      <strong>Du är alltid välkommen att kontakta oss om du har några frågor.</strong>
    </p>
  </div>
</template>

<script>
export default {
  name: 'ThankYouPage'
}
</script>
