<template>
  <div id="product">
    <section class="section is-main">
      <div class="container box is-main">
        <div class="content">
          <h2 class="title is-2">Sidan du sökte kunde inte hittas.</h2>
          <p>
            Vänligen kontakta oss om du inte kan hitta vad du letar efter.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'PageNotFound',
  created () {
    document.title = '404 Sidan kunde inte hittas'
  }
}
</script>
