<template>
  <div v-if="product.in_stock" class="field has-addons has-addons-left">
    <p class="control">
      <input v-model="qty" type="number" name="qty" class="input cart-qty" maxlength="12" value="1">
    </p>
    <div>
      <button-full :buttonClass="'button is-link has-text-white'" @click.native="addToCart()" :disabled="addToCartDisable">
        <span class="is-size-6 has-text-weight-bold" v-if="product.in_stock">{{ addToCartTitle }}</span>
      </button-full>
    </div>
  </div>
  <p class="has-text-danger" v-else>Ej i lager</p>
</template>

<script>
import ButtonFull from '../../../components/blocks/ButtonFull.vue'

export default {
  name: 'AddToCart',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      qty: 1,
      addToCartTitle: 'Lägg i kundvagn',
      addToCartDisable: false
    }
  },
  methods: {
    addToCart () {
      this.addToCartTitle = 'Lagd i Kundvagn'
      this.addToCartDisable = true
      this.$store.dispatch('cart/addItem', { sku: this.product.sku, qty: parseInt(this.qty) })
    }
  },
  components: { ButtonFull }
}
</script>
