<template>
  <div class="py-5">
    <h3 class="title is-3 has-text-weight-normal">Betalningssätt</h3>
    <div class="tags">
      <a v-for="method in availablePaymentMethods" :key="method.code"
          :class="{ 'is-primary': selectedPaymentMethod.code === method.code }"
          @click="setPaymentMethod(method.code)"
          class="tag is-medium has-no-decoration">
          {{ method.title }}
      </a>
    </div>
    <component :is="paymentComponents[selectedPaymentMethod.code]" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Countries from '../../assets/countries.json'
import Invoice from '../paymentMethods/Invoice.vue'
import wasaKredit from '../paymentMethods/wasa/Wasa.vue'

export default {
  name: 'Payment',
  data () {
    return {
      countries: Countries,
      paymentComponents: {
        invoice: Invoice,
        wasa_kredit: wasaKredit
      }
    }
  },
  computed: {
    ...mapState({
      selectedPaymentMethod: state => state.cart.selectedPayment,
      availablePaymentMethods: state => state.cart.availablePaymentMethods
    })
  },
  mounted () {
    this.$store.dispatch('cart/sortPaymentMethods')
  },
  methods: {
    setPaymentMethod (value) {
      this.$store.dispatch('cart/setPaymentMethod', value)
    }
  },
  components: {
    Invoice,
    wasaKredit
  }
}
</script>
