<template>
  <div id="app">
    <div id="nav">
      <Header/>
      <router-view/>
      <Footer/>
    </div>
  </div>
</template>

<script>
import Header from './components/header/Header.vue'
import Footer from './components/blocks/Footer.vue'

export default {
  components: {
    Header,
    Footer
  }
}
</script>
<style lang="sass" src="./css/main.sass"></style>
