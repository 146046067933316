const state = {
  contactModalActive: false,
  mobileMenuActive: false,
  cartDropDownActive: false
}

const actions = {
  setContactForm ({ commit }, value) {
    commit('setContactForm', value)
  },
  setMobileMenu ({ commit }, value) {
    commit('setMobileMenu', value)
  },
  setCartDropdown ({ commit }, value) {
    commit('setCartDropdown', value)
  }
}

const mutations = {
  setContactForm (state, value) {
    state.contactModalActive = value
  },
  setMobileMenu (state, value) {
    state.mobileMenuActive = value
  },
  setCartDropdown (state, value) {
    state.cartDropDownActive = value
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
