var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"title is-3 has-text-weight-normal"},[_vm._v(" "+_vm._s(_vm.shipping.sameAsBilling ? 'Adress' : 'Fakturaaddress')+" ")]),_c('personal-details'),_c('base-input',{attrs:{"name":"name","labelText":"Namn*","value":_vm.payment.name,"autocomplete":"name","validations":[{
          error: _vm.$v.payment.name.$error && !_vm.$v.payment.name.required,
          text: 'Obligatorisk uppgift'
        }]},on:{"blur":function($event){return _vm.$v.payment.name.$touch()}},nativeOn:{"input":function($event){return _vm.setPaymentProp(['name', $event.target.value])}}}),_c('base-input',{attrs:{"name":"street-address","labelText":"Adress*","value":_vm.payment.streetAddress,"autocomplete":"address-line1","validations":[{
          error: _vm.$v.payment.streetAddress.$error && !_vm.$v.payment.streetAddress.required,
          text: 'Obligatorisk uppgift'
        }]},on:{"blur":function($event){return _vm.$v.payment.streetAddress.$touch()}},nativeOn:{"input":function($event){return _vm.setPaymentProp(['streetAddress', $event.target.value])}}}),_c('base-input',{attrs:{"name":"phone","inputmode":"numeric","labelText":"Telefonnummer*","value":_vm.payment.phoneNumber,"autocomplete":"tel","validations":[{
          error: _vm.$v.payment.phoneNumber.$error && !_vm.$v.payment.phoneNumber.required,
          text: 'Obligatorisk uppgift'
        }]},on:{"blur":function($event){return _vm.$v.payment.phoneNumber.$touch()}},nativeOn:{"input":function($event){return _vm.setPaymentProp(['phoneNumber', $event.target.value])}}}),_c('base-input',{attrs:{"name":"zip-code","inputmode":"numeric","labelText":"Postnummer*","value":_vm.payment.zipCode,"autocomplete":"postal-code","validations":[{
          error: _vm.$v.payment.zipCode.$error && !_vm.$v.payment.zipCode.required,
          text: 'Obligatorisk uppgift'
        }]},on:{"blur":function($event){return _vm.$v.payment.zipCode.$touch()}},nativeOn:{"input":function($event){return _vm.setPaymentProp(['zipCode', $event.target.value])}}}),_c('base-input',{attrs:{"name":"city","labelText":"Ort*","value":_vm.payment.city,"autocomplete":"address-level2","validations":[{
          error: _vm.$v.payment.city.$error && !_vm.$v.payment.city.required,
          text: 'Obligatorisk uppgift'
        }]},on:{"blur":function($event){return _vm.$v.payment.city.$touch()}},nativeOn:{"input":function($event){return _vm.setPaymentProp(['city', $event.target.value])}}}),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Land")]),_c('div',{staticClass:"control"},[_c('div',{staticClass:"select"},[_c('select',{attrs:{"name":"countries","autocomplete":"country"},domProps:{"value":_vm.payment.country},on:{"change":function($event){_vm.setPaymentProp(['country', $event.target.value]); _vm.$v.payment.country.$touch()}}},[_c('option',{attrs:{"value":"","disabled":"","selected":"","hidden":""}},[_vm._v("Land*")]),_vm._l((_vm.countries),function(country){return _c('option',{key:country.code,domProps:{"value":country.name}},[_vm._v(_vm._s(country.name))])})],2),(_vm.$v.payment.country.$error && !_vm.$v.payment.country.required)?_c('span',{staticClass:"validation-error"},[_vm._v(" "+_vm._s('Obligatorisk uppgift')+" ")]):_vm._e()])])]),_c('base-checkbox',{attrs:{"id":"sameAsBillingCheckbox","value":_vm.shipping.sameAsBilling},on:{"click":function($event){_vm.setShippingProp(['sameAsBilling', !_vm.shipping.sameAsBilling]); _vm.resetShipping()}}},[_vm._v(" Samma leveransadress ")]),(!_vm.shipping.sameAsBilling)?[_c('h3',{staticClass:"title is-3 has-text-weight-normal"},[_vm._v("Leveransadress")]),_c('base-input',{attrs:{"name":"name","labelText":"Namn*","value":_vm.shipping.name,"autocomplete":"name","validations":[{
        error: _vm.$v.shipping.name.$error && !_vm.$v.shipping.name.required,
        text: 'Obligatorisk uppgift'
      }]},on:{"blur":function($event){return _vm.$v.shipping.name.$touch()}},nativeOn:{"input":function($event){return _vm.setShippingProp(['name', $event.target.value])}}}),_c('base-input',{attrs:{"name":"street-address","labelText":"Adress*","value":_vm.shipping.streetAddress,"autocomplete":"address-line1","validations":[{
        error: _vm.$v.shipping.streetAddress.$error && !_vm.$v.shipping.streetAddress.required,
        text: 'Obligatorisk uppgift'
      }]},on:{"blur":function($event){return _vm.$v.shipping.streetAddress.$touch()}},nativeOn:{"input":function($event){return _vm.setShippingProp(['streetAddress', $event.target.value])}}}),_c('base-input',{attrs:{"name":"phone","inputmode":"numeric","labelText":"Telefonnummer*","value":_vm.shipping.phoneNumber,"autocomplete":"tel","validations":[{
        error: _vm.$v.shipping.phoneNumber.$error && !_vm.$v.shipping.phoneNumber.required,
        text: 'Obligatorisk uppgift'
      }]},on:{"blur":function($event){return _vm.$v.shipping.phoneNumber.$touch()}},nativeOn:{"input":function($event){return _vm.setShippingProp(['phoneNumber', $event.target.value])}}}),_c('base-input',{attrs:{"name":"zip-code","inputmode":"numeric","labelText":"Postnummer*","value":_vm.shipping.zipCode,"autocomplete":"address-level2","validations":[{
        error: _vm.$v.shipping.zipCode.$error && !_vm.$v.shipping.zipCode.required,
        text: 'Obligatorisk uppgift'
      }]},on:{"blur":function($event){return _vm.$v.shipping.zipCode.$touch()}},nativeOn:{"input":function($event){return _vm.setShippingProp(['zipCode', $event.target.value])}}}),_c('base-input',{attrs:{"name":"city","labelText":"Ort*","value":_vm.shipping.city,"autocomplete":"city","validations":[{
        error: _vm.$v.shipping.city.$error && !_vm.$v.shipping.city.required,
        text: 'Obligatorisk uppgift'
      }]},on:{"blur":function($event){return _vm.$v.shipping.city.$touch()}},nativeOn:{"input":function($event){return _vm.setShippingProp(['city', $event.target.value])}}}),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v("Land")]),_c('div',{staticClass:"control"},[_c('div',{staticClass:"select"},[_c('select',{attrs:{"name":"countries","autocomplete":"country"},domProps:{"value":_vm.shipping.country},on:{"change":function($event){_vm.setShippingProp(['country', $event.target.value]); _vm.$v.shipping.country.$touch()}}},[_c('option',{attrs:{"value":"","disabled":"","selected":"","hidden":""}},[_vm._v("Land*")]),_vm._l((_vm.countries),function(country){return _c('option',{key:country.code,domProps:{"value":country.name}},[_vm._v(_vm._s(country.name))])})],2),(_vm.$v.shipping.country.$error && !_vm.$v.shipping.country.required)?_c('span',{staticClass:"validation-error"},[_vm._v(" "+_vm._s('Obligatorisk uppgift')+" ")]):_vm._e()])])])]:_vm._e(),_c('h3',{staticClass:"title is-3 has-text-weight-normal"},[_vm._v(" Frakt ")]),_c('div',{staticClass:"tags"},_vm._l((_vm.cart.availableCarriers),function(method,index){return _c('a',{key:index,staticClass:"tag is-medium has-no-decoration",class:{ 'is-primary': _vm.cart.selectedCarrier.carrier_code === method.carrier_code },on:{"click":function($event){return _vm.setNewCarrier(method)}}},[_vm._v(" "+_vm._s(method.carrier_title)+" "+_vm._s(_vm._f("sek")(method.price_incl_tax))+" ")])}),0)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }