<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column is-narrow">
          <div class="info-map">
            <iframe width="425" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" style="height: 350px"
                    src="https://www.openstreetmap.org/export/embed.html?bbox=13.240349292755129%2C57.48070614191007%2C15.533745288848879%2C58.1427424051916&amp;layer=mapnik&amp;marker=57.80278%2C14.44102"
            />
          </div>
        </div>
        <div class="column">
          <div class="content">
            <h1 class="title is-1">Information</h1>
            <div class="columns">
              <div class="column">
                <table class="table">
                  <tbody>
                    <tr>
                      <th>
                        Företagsnamn
                      </th>
                      <td>
                        Lunna Teknik AB
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Adress
                      </th>
                      <td>
                        Svarttorp Lunnagård 1<br>561 96 Lekeryd
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Org.nummer
                      </th>
                      <td>
                        559270-7185
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Telefon / SMS
                      </th>
                      <td>
                        073-392 39 65
                      </td>
                    </tr>
                    <tr>
                      <th>
                        E-post
                      </th>
                      <td>
                        <a href="mailto:info@lunnateknik.se">info@lunnateknik.se</a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <h2 class="title is-2">Kontakt</h2>
            <p>
              Vill du komma i kontakt med oss kan du göra det genom att maila, ringa eller använda kontaktformuläret i menyn längst upp.
            </p>
            <h2 class="title is-2">Leveransinformation</h2>
            <p>
              Vi försöker alltid att skicka reservdelar samma dag som de beställs. Större försändelser skickas vanligtvis nästa dag.
              Om din beställning är spårbar kommer du att få ett mail med en länk där du kan följa din försändelse.
            </p>
            <p>
              Vi levererar till Sverige, Danmark, Norge och Finland om inte annat anges. Skulle en vara få fördröjd leveranstid kommer vi att ta kontakt och informera dig.
            </p>
            <h2 class="title is-2">Returinformation</h2>
            <p>
              Om du ångrar din beställning ber vi dig att kontakta oss för mer information.
            </p>
            <p>
              Enligt Distans- och hemförsäljningslagen har du som konsument (gäller ej företag) rätt att frånträda avtalet genom att meddela oss detta inom 14 dagar från mottagandet av varan.
              För att du skall kunna nyttja din ångerrätt måste du returnera varan i oskadat skick, i originalförpackning. Vid nyttjande av ångerrätten är det du som står för returkostnaden.
            </p>
            <h2 class="title is-2">Betalinformation</h2>
            <p>
              Vi erbjuder betalning med faktura. Du har 10 dagars kredit från och med att du har fått varan.
              Om du beställer från utlandet måste du betala fakturan i förskott.
            </p>
            <h2 class="title is-2">Dataskydd</h2>
            <p>
              Vi behöver spara och behandla personuppgifter om dig, så som ditt namn, adress och telefonnummer.
              Syftet med en sådan behandling är för att kunna hantera beställningar och köp.
            </p>
            <p>
              Vi tillämpar vid var tid gällande integritetslagstiftning vid all behandling av personuppgifter.
              Den rättsliga grunden för att behandla dina personuppgifter är det avtal som ingås vid köp.
            </p>
            <p>
              Vi kan även komma att dela dina personuppgifter med en tredje part, förutsatt att vi är skyldiga att göra så enligt lag.
              Däremot kommer vi aldrig att överföra dina uppgifter till ett land utanför EU.
              Personuppgiftsansvarig är Lunna Teknik.
              Du har rätt att kontakta oss om du vill ha ut information om de uppgifter vi har om dig, för att begära rättelse,
              överföring eller för att begära att vi begränsar behandlingen, för att göra invändningar eller begära radering av  dina uppgifter.
              Detta gör du enklast genom att kontakta oss på <a href="mailto:info@lunnateknik.se.">info@lunnateknik.se</a>.
              Om du har klagomål på vår behandling av dina personuppgifter har du rätt att ange klagomål till tillsynsmyndigheten Datainspektionen.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Information',
  created () {
    document.title = 'Information | Lunna Teknik'
  }
}
</script>
