<template>
  <transition name="fade">
    <div class="modal" :class="{ 'is-active': isActive }" v-if="isActive">
      <div class="modal-background" @click="setContactForm(false)"/>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Kontaktformulär</p>
        </header>
        <section class="modal-card-body">
          <article class="message is-hidden-mobile">
            <div class="message-body">
              Lämna din fråga i fältet nedanför. Vi återkommer så snabbt vi kan.<br>
              Det går även bra att ringa eller maila oss.<br>
              Telefon: 073-392 39 65<br>
              Mail: info@lunnateknik.se
            </div>
          </article>
          <form>
            <div class="field">
              <label class="label">Namn*</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  name="name"
                  autocomplete="name"
                  v-model="$v.customer.name.$model"
                  :disable="disableSend"
                >
                <p class="help is-danger" v-if="$v.customer.name.$error && !$v.customer.name.required">
                  Obligatorisk uppgift
                </p>
              </div>
            </div>

            <div class="field is-horizontal">
              <div class="field-body">
                <div class="field">
                  <label class="label">E-post*</label>
                  <div class="control">
                    <input
                      class="input"
                      type="email"
                      name="email-address"
                      autocomplete="email"
                      v-model="$v.customer.email.$model"
                      :disable="disableSend"
                    >
                    <p class="help is-danger" v-if="$v.customer.email.$error && !$v.customer.email.required">
                      Obligatorisk uppgift
                    </p>
                    <p class="help is-danger" v-if="!$v.customer.email.email && $v.customer.email.$error">
                      Vänligen ange en giltig e-post
                    </p>
                  </div>
                </div>

                <div class="field">
                  <label class="label">Telefonnummer</label>
                  <div class="control">
                    <input
                      class="input"
                      type="tel"
                      name="phone-number"
                      v-model="customer.telephone"
                      autocomplete="phone-number"
                      :disable="disableSend"
                    >
                  </div>
                </div>
              </div>
            </div>

            <textarea class="textarea" placeholder="Din fråga…" v-model="customer.message" :disabled="disableSend" />
          </form>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" @click="sendContactForm" :class="{ 'is-loading': isSending }" :disabled="$v.customer.$invalid || disableSend || showErrorMessage">{{ sendButtonText }}</button>
          <button class="button" type="button" @click="setContactForm(false)">Stäng</button>
          <p v-if="showErrorMessage" class="has-text-danger">Kunde inte skicka kontaktformulär. Vänligen maila oss istället på <a href="mailto:info@lunnateknik.se">info@lunnateknik.se</a></p>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios'

import { mapState } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: 'ContactForm',
  data () {
    return {
      customer: {
        name: '',
        email: '',
        telephone: '',
        message: '',
        product: ''
      },
      showErrorMessage: false,
      isSending: false,
      disableSend: false,
      sendButtonText: 'Skicka'
    }
  },
  validations: {
    customer: {
      name: {
        required
      },
      email: {
        email,
        required
      }
    }
  },
  computed: {
    ...mapState({
      isActive: state => state.ui.contactModalActive,
      lastProduct: state => state.cart.lastProduct
    })
  },
  methods: {
    sendContactForm () {
      this.isSending = true
      if (this.lastProduct) {
        this.customer.product = this.lastProduct
      }
      const contactFormURL = `${this.$apiURL}/ses/sendContactForm`
      axios.post(contactFormURL, this.customer)
        .then(_ => {
          this.isSending = false
          this.sendButtonText = 'Skickat!'
          this.disableSend = true
        })
        .catch(_ => {
          this.isSending = false
          this.showErrorMessage = true
        })
    },
    setContactForm (value) {
      this.$v.$reset()
      this.customer = {
        name: '',
        email: '',
        telephone: '',
        message: '',
        product: ''
      }
      this.showErrorMessage = false
      this.isSending = false
      this.sendButtonText = 'Skicka'
      this.disableSend = false
      this.$store.dispatch('ui/setContactForm', value)
    }
  }
}
</script>
