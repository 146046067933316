<template>
  <div id="product">
    <section class="section is-main" itemscope itemtype="https://schema.org/Product">
      <div class="container is-main">
        <breadcrumbs :routes="[{ route_link: category.url, name: category.title }]" :active-route="product.name"/>
        <div class="columns is-multiline product-column">
          <image-viewer :product="product" />
          <component :is="productView" :product="product" />
          <hr />
        </div>
      </div>
      <info-repository v-if="product.hasOwnProperty('info_repository_key')" :product="product" />
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Breadcrumbs from '../components/blocks/Breadcrumbs.vue'
import ImageViewer from './subcomponents/product/ImageViewer.vue'
import Shipping from './subcomponents/product/Shipping.vue'
import InfoRepository from './subcomponents/product/InfoRepository.vue'

import SingleProduct from './subcomponents/product/SingleProduct.vue'
import GroupedProduct from './subcomponents/product/GroupedProduct.vue'

export default {
  name: 'Product',
  computed: {
    ...mapState({
      categories: state => state.inventory.categories,
      products: state => state.inventory.products
    }),
    category () {
      return this.categories[this.product.category]
    },
    product () {
      return this.products[this.$route.params.sku]
    },
    productView () {
      return (this.product.grouped_product ? GroupedProduct : SingleProduct)
    }
  },
  created () {
    document.title = this.product.name + ' | Lunna Teknik'
    this.$store.dispatch('cart/setLastProduct', { sku: this.$route.params.sku })
  },
  components: {
    Breadcrumbs,
    ImageViewer,
    Shipping,
    InfoRepository,
    GroupedProduct,
    SingleProduct
  }
}
</script>
