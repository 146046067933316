<template>
  <div>
    <p class="has-text-success" v-if="product.in_stock">I lager (1–2 vardagar leveranstid)</p>
    <div class="tags mt-2">
      <span v-for="(method, index) in availableShippingMethods" :key="index" class="tag is-size-6">{{ method.carrier_title }}: {{ method.price_incl_tax | sek }}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Shipping',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    availableShippingMethods () {
      const allShippingMethods = this.$store.state.cart.shippingMethods
      const prodShippingMethods = this.product.shipping_methods
      return prodShippingMethods.map(x => allShippingMethods[x])
    }
  }
}
</script>
