<template>
  <picture>
    <source
      :srcset="image_webp"
      type="image/webp"
    >
    <source
      :srcset="image_jpeg"
      type="image/jpeg"
    >
    <img
      :src="image_initial"
      :class="htmlClass"
      ref="imageBox"
    >
  </picture>
</template>

<script>
export default {
  name: 'ProductImage',
  data: function () {
    return {
      imageWidth: 0,
      imageHeight: 0,
      initialImage: 'data:image/svg+xml;charset=UTF-8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><rect width="100" height="100" fill="white" /></svg>'
    }
  },
  computed: {
    image_webp () {
      let path = ''
      if (this.imageWidth > 0) path = this.createString('webp')
      return path
    },
    image_jpeg () {
      let path = ''
      if (this.imageWidth > 0) path = this.createString('jpeg')
      return path
    },
    image_initial () {
      let path = this.initialImage
      if (this.imageWidth > 0) path = this.createImageURI('jpeg')
      return path
    }
  },
  mounted () {
    this.imageWidth = this.$refs.imageBox.clientWidth
    if (this.dimensions) {
      if (this.dimensions.width !== 0) this.imageWidth = this.dimensions.width
      if (this.dimensions.imageHeight !== 0) this.imageHeight = this.dimensions.height
    }
  },
  methods: {
    createImageURI (format) {
      if (process.env.NODE_ENV === 'development') {
        const testPath = 'http://192.168.1.136:3050/api/sharpened/serve/'
        let fixedPath = this.img.img_base.split('/').slice(3, 6).join('/')
        fixedPath = testPath + fixedPath
        return fixedPath + '.' + format
      }
      if (process.env.NODE_ENV === 'production') {
        return `${this.img.img_base}.${format}`
      }
    },
    createURL (format, scale) {
      return `${this.createImageURI(format)}?width=${this.imageWidth}&height=${this.imageHeight}&scale=${scale} ${scale}x`
    },
    createString (format) {
      let path = ''
      for (let index = 0; index < 3; index++) {
        path += this.createURL(format, index + 1)
        if (index < 2) path += ', '
      }
      return path
    }
  },
  props: {
    img: {
      type: Object,
      required: true
    },
    dimensions: {
      type: Object,
      required: false
    },
    htmlClass: {
      type: String,
      required: false
    }
  }
}
</script>
