<template>
  <component
    :is="link ? 'router-link' : 'button'"
    :type="!link ? type : false"
    :to="link"
    :class="buttonClass"
  >
    <slot>
      Button
    </slot>
  </component>
</template>

<script>
export default {
  name: 'ButtonFull',
  props: {
    buttonClass: {
      type: String,
      required: false,
      default: 'button is-primary'
    },
    type: {
      type: String,
      required: false,
      default: 'button'
    },
    link: {
      type: Object,
      required: false,
      default: null
    }
  }
}
</script>
