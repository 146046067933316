<template>
  <div class="field">
    <label class="label">{{ labelText }}</label>
    <div class="control">
      <input
        class="input"
        :name="name"
        :inputmode="inputmode"
        :autocomplete="autocomplete"
        :value="value"
        @blur="$emit('blur')"
        :disabled="disabled"
      >

      <template v-if="activeValidations.length > 0">
        <p
          v-for="(validation, index) in activeValidations"
          :key="index"
          class="help is-danger"
        >
          {{ validation.text }}
        </p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    activeValidations () {
      return this.validations.filter(v => v.error)
    }
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    inputmode: {
      type: String,
      required: false
    },
    value: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: false
    },
    labelText: {
      type: String,
      required: false
    },
    autocomplete: {
      type: String,
      required: false
    },
    validations: {
      type: Array,
      required: false,
      default: function () { return [] }
    }
  }
}
</script>
