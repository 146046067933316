<template>
  <div class="media pt-4">
    <div class="media-left product-thumbnails">
      <product-image :img="product.img_list[0]" :dimensions="{ width: 64, height: 64 }" :htmlClass="'card-image-source'" />
    </div>
    <div class="media-content">
      <div class="content">
        <h3 class="title is-5 cart-title">
          {{ product.name }}
        </h3>
      </div>
      <nav class="level mb-4">
        <div class="level-left">
          <input
            class="input level-item cart-qty"
            v-model="qty"
            type="number"
            min="1" max="150"
          >
          <span class="tag is-medium level-item">
            {{ product.price | sek }}
            <button class="delete is-small" @click="removeItem"/>
          </span>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import ProductImage from '../blocks/ProductImage.vue'

export default {
  name: 'Product',
  props: {
    sku: {
      type: String,
      required: true
    }
  },
  computed: {
    qty: {
      get () {
        return this.$store.state.cart.cartItems[this.sku]
      },
      set (value) {
        this.$store.dispatch('cart/updateQty', { sku: this.product.sku, qty: parseInt(value) })
      }
    },
    product () {
      return this.$store.state.inventory.products[this.sku]
    }
  },
  methods: {
    removeItem () {
      this.$store.dispatch('cart/removeItem', { sku: this.product.sku })
    }
  },
  components: {
    ProductImage
  }
}
</script>
