<template>
  <div class="table-container">
    <table class="table is-hoverable is-striped is-fullwidth">
      <tbody>
        <tr>
          <th>Spänning / Fas</th>
          <td>230V / 1-Fas</td>
        </tr>
        <tr>
          <th>Minsta Säkring</th>
          <td>10A</td>
        </tr>
        <tr>
          <th>Vätska 10°C / Vatten 35°C</th>
          <td>In: 1,3kW / Ut: 7,6kW / COP 5,8</td>
        </tr>
        <tr>
          <th>Vätska 0°C / Vatten 35°C</th>
          <td>In: 1,3kW / Ut: 5,5kW / COP 4,3</td>
        </tr>
        <tr>
          <th>Vätska 0°C / Vatten 55°C</th>
          <td>In: 1,75kW / Ut: 4,9kW / COP 2,8</td>
        </tr>
        <tr>
          <th>Ljudnivå</th>
          <td>40dB vid 3m</td>
        </tr>
        <tr>
          <th>Kompressor</th>
          <td>GMCC Rotary (Fast hastighet)</td>
        </tr>
        <tr>
          <th>Kondensor</th>
          <td>Tube-in-shell</td>
        </tr>
        <tr>
          <th>Förångare</th>
          <td>Plattvärmeväxlare</td>
        </tr>
        <tr>
          <th>Reglering Förångare</th>
          <td>Elektronisk Expansionsventil</td>
        </tr>
        <tr>
          <th>Vatttenflödesvolym</th>
          <td>
            0,8m
            <sup>3</sup>/h
          </td>
        </tr>
        <tr>
          <th>Högsta Kondenseringstemperatur</th>
          <td>55°C</td>
        </tr>
        <tr>
          <th>Anslutningsgänga</th>
          <td>R20</td>
        </tr>
        <tr>
          <th>Anslutningsrör (Vatten)</th>
          <td>Minst 22mm koppar / 20mm PEX</td>
        </tr>
        <tr>
          <th>Cirkulationspump (Värmebärare)</th>
          <td>Inbyggd A-klass</td>
        </tr>
        <tr>
          <th>Cirkulationspump (Köldbärare)</th>
          <td>Extern A-klass (Medföljer)</td>
        </tr>
        <tr>
          <th>Köldmedium</th>
          <td>R410A</td>
        </tr>
        <tr>
          <th>Köldmediummängd</th>
          <td>950g</td>
        </tr>
        <tr>
          <th>Vattenfilter</th>
          <td>Extern filterball med magnet (Medföljer)</td>
        </tr>
        <tr>
          <th>Vikt</th>
          <td>75kg</td>
        </tr>
        <tr>
          <th>Mått (LxBxH)</th>
          <td>540x540x714mm</td>
        </tr>
        <tr>
          <th>Garanti</th>
          <td>2 år</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'IRWW6TekniskSpec'
}
</script>
