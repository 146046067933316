<template>
  <div>
    <figure>
      <img style="height: 55px;" src="/assets/external/wasa-kredit.png" />
    </figure>
    <p><strong>Delbetala med Wasa Kredit</strong></p>
    <p>Tillsammans med Wasa Kredit erbjuder vi dig som kund räntefri delbetalning. Wasa Kredit är ett helägt dotterbolag till Länsförsäkringar Bank med över 30 år på svenska marknaden. De vänder sig till både företag och privatpersoner och jobbar efter värdeorden snabbt, enkelt och personligt.</p>
    <div class="tags mt-4 is-hidden-tablet">
      <a v-for="(item, index) in Object.keys(options)" :key="index"  class="tag is-medium" :class="{ 'is-primary': wasa_method.selectedOption === item }" @click="setOption('selectedOption', item)">{{ item }}</a>
    </div>
    <div class="tabs is-toggle is-fullwidth is-centered mt-4 mb-2 is-hidden-mobile">
      <ul>
        <li v-for="(item, index) in Object.keys(options)" :key="index" :class="{ 'is-active': wasa_method.selectedOption === item }"><a @click="setOption('selectedOption', item)">{{ item }}</a></li>
      </ul>
    </div>
    <component :is="options[wasa_method.selectedOption]" :totalPrice="cartTotal" :selectable="true" />
    <base-input
      class="mt-4"
      inputmode="numeric"
      name="inkomst"
      :value="wasa_method.salary"
      labelText="Din månadsinkomst*"
      @input.native="setOption('salary', $event.target.value)"
      @blur="$v.wasa_method.salary.$touch()"
      :validations="[{
        error: $v.wasa_method.salary.$error && !$v.wasa_method.salary.required,
        text: 'Obligatorisk uppgift'
      }]"
    />
    <p>
      När du har slutfört beställningen behöver du signera ett avtal med Wasa Kredit. Om du har Mobilt BankID kan du signera digitalt annars kommer vi att
      skicka ett distansavtal och returkuvert med brev.
    </p>
    <base-checkbox
      id="sameAsBillingCheckbox"
      class="mt-2"
      :value="wasa_method.mobiltBankID"
      @click="setOption('mobiltBankID', !wasa_method.mobiltBankID)"
    >
      Jag har Mobilt BankID
    </base-checkbox>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import BaseInput from '../../form/BaseInput.vue'
import BaseCheckbox from '../../form/BaseCheckbox.vue'
import wasaKreditRantefri from './options/rantefri.vue'
// import wasaKreditLagRanta from './options/lagranta.vue'
// import wasaKreditBetalningsfri from './options/betalningsfri.vue'

export default {
  name: 'wasaKredit',
  computed: {
    ...mapState({
      wasa_method: (state) => state.cart.selectedPayment
    }),
    cartTotal () {
      return this.$store.getters['cart/totals'].grand_total.value
    }
  },
  methods: {
    setOption (option, value) {
      this.$store.dispatch('cart/setSelectedPaymentOption', { option: option, value: value })
    }
  },
  validations: {
    wasa_method: {
      salary: {
        required
      }
    }
  },
  components: {
    BaseInput,
    BaseCheckbox,
    wasaKreditRantefri
    // wasaKreditLagRanta,
    // wasaKreditBetalningsfri
  },
  data () {
    return {
      options: {
        'Räntefri delbetalning': wasaKreditRantefri
      }
    }
  }
}
</script>
