<template>
  <div>
    <div class="columns is-centered">
      <div class="column is-half is-flex is-justify-content-center is-align-items-center" id="ledyer-container"></div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'BusinessCheckout',
  computed: {
    ...mapState({
      cart: state => state.cart,
      orderPlaced: state => state.checkout.orderPlaced,
      ledyerSession: state => state.checkout.ledyerSession,
      products: state => state.inventory.products
    }),
    ...mapGetters({ cartUUID: 'cart/cartUUID' })
  },
  data () {
    return {
      checkoutComplete: false,
      abortController: new AbortController(),
      retryCount: 0,
      maxRetries: 3
    }
  },
  watch: {
    cartUUID: async function () {
      if (!this.checkoutComplete && window.ledyer && window.ledyer.api) {
        window.ledyer.api.suspend()
        await this.handleSession()
        window.ledyer.api.resume()
      }
    }
  },
  mounted () {
    document.addEventListener('ledyerCheckoutOrderComplete', this.handleLedyerCheckoutOrderComplete)
    this.handleSession()
  },
  beforeDestroy () {
    window.ledyer.api.suspend()
    this.abortController.abort()
    this.removeLedyerScript()
    if (this.checkoutComplete) {
      this.$store.dispatch('checkout/setLedyerSession', null)
      this.$store.dispatch('cart/removeDiscount')
    }
  },
  methods: {
    insertLedyerScript () {
      if (!this.ledyerSession) {
        console.error('No Ledyer session found.')
        return
      }
      if (document.getElementById('ledyer-script')) {
        console.log('Ledyer script already exists.')
        return
      }
      const dataEnv = process.env.NODE_ENV === 'development' ? 'sandbox' : 'production'
      const script = document.createElement('script')
      script.id = 'ledyer-script'
      script.setAttribute('data-brand-color', '#00204d')
      script.setAttribute('data-env', dataEnv)
      script.setAttribute('data-no-padding', 'true')
      script.setAttribute('data-container-id', 'ledyer-container')
      script.setAttribute('data-session-id', this.ledyerSession?.sessionId)
      script.setAttribute('src', this.$ledyerScriptURL)
      script.onload = () => {
        console.log('Ledyer script loaded.')
        // Any additional initialization can go here
      }
      script.onerror = () => {
        console.error('Failed to load Ledyer script.')
      }
      document.body.appendChild(script)
    },
    removeLedyerScript () {
      document.removeEventListener('ledyerCheckoutOrderComplete', this.handleLedyerCheckoutOrderComplete)
      const script = document.getElementById('ledyer-script')
      if (script) {
        console.log('Removing Ledyer script...')
        script.remove()
      } else {
        console.log('Ledyer script not found.')
      }
    },
    handleLedyerCheckoutOrderComplete () {
      this.checkoutComplete = true
      this.$store.dispatch('checkout/setOrderPlaced', true)
      this.$store.dispatch('cart/clearCart')
      window.ledyer.api.resume()
    },
    async handleSession () {
      const cartItems = this.prepareCart()
      const sessionExists = this.ledyerSession && this.ledyerSession.sessionId
      const url = sessionExists
        ? `${this.$apiURL}/services/updateOrderSession/`
        : `${this.$apiURL}/services/createOrderSession/`

      const data = sessionExists
        ? {
          sessionId: this.ledyerSession.sessionId,
          order: {
            cartItems,
            carrier: this.cart.selectedCarrier,
            discount: this.cart.discount
          }
        }
        : {
          cartItems,
          carrier: this.cart.selectedCarrier,
          discount: this.cart.discount
        }

      try {
        const res = await axios.post(url, data, { signal: this.abortController.signal })

        if (sessionExists) {
          if (res.status === 200) {
            this.insertLedyerScript()
          } else {
            throw new Error('Failed to update session')
          }
        } else {
          if (res.data && res.data.sessionId) {
            this.$store.dispatch('checkout/setLedyerSession', res.data)
            this.insertLedyerScript()
          } else {
            throw new Error('Invalid session data received')
          }
        }
      } catch (error) {
        console.error('Error handling Ledyer session:', error)
        if (sessionExists) {
          if (this.retryCount < this.maxRetries) {
            this.retryCount++
            this.$store.dispatch('checkout/setLedyerSession', null)
            await this.handleSession()
          } else {
            // Notify the user or handle the failure gracefully
            console.error('Maximum retry attempts reached.')
          }
        } else {
          // Handle the error when no session exists
          console.error('Failed to create a new session.')
        }
      }
    },
    prepareCart () {
      const cartProducts = []
      for (const sku in this.cart.cartItems) {
        cartProducts.push({
          sku: sku,
          qty: this.cart.cartItems[sku],
          name: this.products[sku].name,
          price: this.products[sku].price
        })
      }
      return cartProducts
    }
  }
}
</script>
