import productsFile from '../../assets/products.json'
import categoriesFile from '../../assets/categories.json'

const state = {
  products: productsFile,
  categories: categoriesFile
}

export default {
  namespaced: true,
  state
}
