<template>
  <footer class="footer">
    <div class="container">
      <div class="content has-text-centered">
        <p>
          Lunna Teknik säljer värmepumpar, TB-Mini och VVS-delar. <strong>Alla priser inkl. moms.</strong><br>
          Du är välkommen att kontakta oss på <a href="mailto:info@lunnateknik.se">info@lunnateknik.se</a>, 073-392 39 65 eller genom kontaktformuläret i huvudmenyn.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
