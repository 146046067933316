<template>
  <div class="column is-one-third product-column">
    <div class="image is-product-image">
      <a @click="isImageModalActive = true">
        <product-image :img="product.img_list[activeImgIndex]" />
      </a>
    </div>
    <div class="modal" :class="{ 'is-active': isImageModalActive }" v-if="isImageModalActive">
      <div class="modal-background" @click="isImageModalActive = !isImageModalActive"/>
      <div class="modal-content">
        <figure class="image">
          <product-image :img="product.img_list[activeImgIndex]" :htmlClass="'is-modal'" />
        </figure>
      </div>
    </div>
    <div class="columns is-multiline is-mobile">
      <div class="column is-narrow" v-for="(img, index) in product.img_list" :key="index">
          <div @click="activeImgIndex = index" class="product-thumbnails">
            <product-image :img="img" :htmlClass="'card-image-source'" :dimensions="{ width: 64, height: 64 }" />
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import ProductImage from '../../../components/blocks/ProductImage.vue'

export default {
  name: 'ImageViewer',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isImageModalActive: false,
      activeImgIndex: 0
    }
  },
  components: {
    ProductImage
  }
}
</script>
